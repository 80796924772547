import { CLID } from 'config/Env';
import { fetchApi, fetchApiRawResponse } from '../config/ApiConfig';
import DV from '../variables/DV';
import { setParamsUrl } from '../variables/utils';
import { getAgentName } from "../helper/helper";

const endPoints = {
  getToken: 'owner/token/',
  getOwnerProfile: 'owner/profile/',
  postCreateCompany: 'owner/create-company/',
};

export const loginByPassword = (data) => () => fetchApi('/auth/basic/', data, 'post', null, '3.0');

export const getToken = (fbToken) => () => {
  let data = {
    grant_type: 'convert_token',
    client_id: CLID,
    backend: 'facebook',
    token: fbToken,
  };
  return fetchApi(endPoints.getToken, data, 'post');
};

export const loginByPassword2 = (data) => () => fetchApi('/auth/basic/', data, 'post', null, '3.0');
export const getAccessToken2 = (token) => () => {
  let payload = {
    token,
    provider: 'facebook',
  };
  return fetchApi('auth/oauth2/convert-token/', payload, 'post', null, '3.0');
};

export const refreshToken = () => () => {
  let payload = {
    refresh_token: DV.refreshToken,
  };
  return fetchApi('auth/oauth2/refresh-token/', payload, 'post', null, '3.0');
};

export const getCompanyDetail = () => () =>
  fetchApi('users/me/companies/' + DV.company.id, null, 'get', null, '2.0');
export const getOwnerProfile =
  (params = '') =>
  () =>
    fetchApi('users/me/' + params, null, 'get', null, '2.0');
export const createCompany = (data) => () =>
  fetchApi('users/me/companies/', data, 'post', null, '2.0');
export const updateCompany = (data) => () =>
  fetchApi('users/me/companies/' + DV.company.id, data, 'put', null, '2.0');
export const joinCompany = (code) => () =>
  fetchApi('users/me/staffs/', { seller_invite_code: code }, 'post', null, '2.0');

//integrate api
export const getApiToken = (staffId) => () =>
  fetchApi('users/me/staffs/' + staffId + '/api-token/', null, 'get', null, '3.0');

export const updateApiToken = (staffId, data) => () =>
  fetchApi('users/me/staffs/' + staffId + '/api-token/', data, 'post', null, '3.0');

export const getRecentWebhookErrors = () => () =>
  fetchApi('companies/' + DV.company.id + '/webhooks/events/', null, 'get', null, '3.0');

//todo: fanpage
export const getFanpages =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/fanpages/' + params, null, 'get');
export const disableFanpage = (id) => () => fetchApi('page/' + id + '/disable/', null, 'put');
export const enableFanpage = (id) => () => fetchApi('page/' + id + '/enable/', null, 'put');
export const deleteFanpage = (id) => () => fetchApi('page/' + id + '/delete/', null, 'delete');

//todo: get matched post of comment trigger condition in scenario from api
export const getMatchedPost =
  (channels, hashtags = []) =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/page/posts/?pages=' + channels + '&hashtags=' + hashtags,
      null,
      'get',
      {},
      '2.0',
    );
export const getPostContentById = (channels, postsId) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/page/posts/?pages=' + channels + '&fb_ids=' + postsId,
    null,
    'get',
    {},
    '2.0',
  );
export const getPostContentByLink = (channels, postsId) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/page/posts/?pages=' + channels + '&permalink_urls=' + postsId,
    null,
    'get',
    {},
    '2.0',
  );

// qr
export const getFanpageQr =
  (id, params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/page/' + id + '/messenger-code/' + params,
      null,
      'get',
      {},
      '2.0',
    );

//todo: zalo OA
export const getZaloOA = (params) => () =>
  fetchApi(`/companies/${DV.company.id}/zalo_oa/`, null, 'get', null, '3.0');
export const disableZaloOA = (id) => () =>
  fetchApi('zalo/oa/' + id + '/disable/', { status: 'publish' }, 'put');
export const enableZaloOA = (id) => () =>
  fetchApi('zalo/oa/' + id + '/enable/', { status: 'disable' }, 'put');
export const updateZaloOA = (id, data) => () => fetchApi('zalo/oa/' + id + '/update/', data, 'put');
export const deleteZaloOA = (id) => () => fetchApi('zalo/oa/' + id + '/delete/', null, 'delete');
export const authorizationZaloOA = (data) => () =>
  fetchApi(`/companies/${DV.company.id}/zalo_oa/authorization/`, data, 'post', null, '3.0');
export const authorizedZaloOA = (data) => () =>
  fetchApi(`/companies/${DV.company.id}/zalo_oa/authorized/`, data, 'post', null, '3.0');

//todo: staffs
export const getStaffs =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/staffs/' + params, null, 'get', null, '2.0');
export const postStaffsGroup = (id, code_name) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/staffs/' + id + '/groups/',
    { code_name },
    'post',
    null,
    '2.0',
  );
export const deleteStaffsGroup = (staffId, id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/staffs/' + staffId + '/groups/' + id,
    null,
    'delete',
    null,
    '2.0',
  );
export const patchOwner = (id, is_owner) => () =>
  fetchApi('companies/' + DV.company.id + '/staffs/' + id, { is_owner }, 'patch', null, '2.0');
export const deleteStaffs = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/staffs/' + id, null, 'delete', null, '2.0');
export const patchStaff = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/staffs/' + id, data, 'patch', null, '2.0');

//todo: invite staff
export const inviteStaffs = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/staffs/invites/', data, 'post', null, '2.0');

//todo: website
export const getWebsites =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/websites/' + params, null, 'get', null, '2.0');
export const addWebsites = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/websites/', data, 'post', null, '2.0');
export const deleteWebsite = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/websites/' + id, null, 'delete', null, '2.0');
export const updateWebsite = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/websites/' + id, data, 'put', null, '2.0');
export const getWebsiteSetting = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/websites/' + id + '/setting', data, 'get', null, '2.0');
export const updateWebsiteSetting = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/websites/' + id + '/setting', data, 'put', null, '2.0');

//todo: product
export const getProducts =
  (params = '') =>
  async () =>
    fetchApi('companies/' + DV.company.id + '/products/' + params, null, 'get', null, '2.0');
export const addProduct = (data) => async () =>
  fetchApi('companies/' + DV.company.id + '/products/', data, 'post', null, '2.0');
export const updateProduct = (id, data) => async () =>
  fetchApi('companies/' + DV.company.id + '/products/' + id, data, 'put', null, '2.0');
export const deleteProduct = (id) => async () =>
  fetchApi('companies/' + DV.company.id + '/products/' + id, null, 'delete', null, '2.0');
export const importProduct = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/products/data', data, 'post', null, '2.0');

//address
export const getProvinces = () => () => fetchApi('address/provinces/', null, 'get');
export const getDistricts = (id) => () => fetchApi('address/' + id + '/districts/', null, 'get');
export const getWards = (id) => () => fetchApi('address/' + id + '/wards/', null, 'get');

//todo: order
export const getOrders =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/orders/' + params, null, 'get', null, '2.0');
export const postMakeCompanyOrder = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/orders/', data, 'post', null, '2.0');
export const cancelOrder = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/orders/' + id, null, 'delete', null, '2.0');
export const confirmOrder = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/orders/' + id, data, 'patch', null, '2.0');
export const addGHTK = (data) => () => fetchApi('owner/accounts/ghtk/add-token', data, 'post');
export const createExtraOrder = (id, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/orders/' + id + '/extra-orders',
    data,
    'post',
    null,
    '2.0',
  );
export const getShipmentServices = (id, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/orders/' + id + '/shipping-services',
    data,
    'post',
    null,
    '2.0',
  );
export const getOrderBillData =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/orders/bill-data/' + params,
      null,
      'get',
      null,
      '2.0',
    );
export const getOrderBulkConfirm =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/orders/bulk-confirm/' + params,
      null,
      'get',
      null,
      '2.0',
    );

//todo: subscriptions
export const getCurrentSubscription =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/subscriptions/current/' + params,
      null,
      'get',
      null,
      '3.0',
    );
export const getListSubscription =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/subscriptions/' + params, null, 'get', null, '2.0');
export const createSubscription = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/subscriptions/', data, 'post', null, '2.0');
export const checkCoupon = (coupon) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/subscriptions/coupons/' + coupon,
    null,
    'get',
    null,
    '2.0',
  );
export const shareFacebook = (data) => () => fetchApi('facebook/share/', data, 'post');
export const updateSubscription = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/subscriptions/' + id, data, 'patch', null, '2.0');
export const getPricingPolicy = () => () =>
  fetchApi('subscriptions/pricing-policy', null, 'get', null, '2.0');
export const getCredits =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/subscriptions/credits' + params,
      null,
      'get',
      null,
      '3.0',
    );
export const getCreditsTransactions =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/subscriptions/credits/transactions/' + params,
      null,
      'get',
      null,
      '3.0',
    );

export const getCreditsTransactionsChartData =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/subscriptions/credits/transactions/chart-data',
      null,
      'get',
      null,
      '3.0',
    );

//todo: Product Attributes
export const getProductAttributes =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/product-attributes/' + params, null, 'get');
export const createProductAttribute = (data) => () =>
  fetchApi('company/' + DV.company.id + '/create-product-attribute/', data, 'post');
export const deleteAttribute = (id) => () =>
  fetchApi('company/product-attributes/' + id + '/delete/', null, 'delete');

//todo:Variants
export const getVariants = (id) => async () =>
  fetchApi(
    'companies/' + DV.company.id + '/products/' + id + '/variants',
    null,
    'get',
    null,
    '2.0',
  );
export const updateVariants = (id, data) => async () =>
  fetchApi('companies/' + DV.company.id + '/products/' + id, data, 'patch', null, '2.0');
export const createVariant = (id, data) => () =>
  fetchApi('company/product/' + id + '/variants/create/', data, 'post');
export const disableVariant = (id) => () => fetchApi('product/' + id + '/disable/', null, 'put');
export const enableVariant = (id) => () => fetchApi('product/' + id + '/enable/', null, 'put');

//todo: Instagram
export const getInstagram = () => () =>
  fetchApi('companies/' + DV.company.id + '/instagram/accounts/', null, 'get', null, '2.0');
export const addInstagram = (data) => () =>
  fetchApiRawResponse(
    'companies/' + DV.company.id + '/instagram/accounts/',
    data,
    'post',
    null,
    '2.0',
  );
export const updateInstagram = (data, id) => () =>
  fetchApiRawResponse(
    'companies/' + DV.company.id + '/instagram/accounts/' + id + '/',
    data,
    'put',
    null,
    '2.0',
  );
export const deleteInstagram = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/instagram/accounts/' + id + '/',
    null,
    'delete',
    null,
    '2.0',
  );
export const enableInstagram = (id) => () =>
  fetchApi('instagram/' + id + '/enable/', null, 'put', null, '2.0');

export const disableInstagram = (id) => () =>
  fetchApi('instagram/' + id + '/disable/', null, 'put');

//todo: FB Personal
export const getFBPersonal = () => () =>
  fetchApi('companies/' + DV.company.id + '/fb_personal/accounts/', null, 'get', null, '2.0');
// export const addFBPersonal = (data) => () =>
//   fetchApiRawResponse(
//     'companies/' + DV.company.id + '/fb_personal/accounts/',
//     data,
//     'post',
//     null,
//     '2.0',
//   );
export const updateFBPersonal = (data, id) => () =>
  fetchApiRawResponse(
    'companies/' + DV.company.id + '/fb_personal/accounts/' + id + '/',
    data,
    'put',
    null,
    '2.0',
  );
export const deleteFBPersonal = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/fb_personal/accounts/' + id + '/',
    null,
    'delete',
    null,
    '2.0',
  );
export const enableFBPersonal = (id) => () =>
  fetchApi('fb_personal/' + id + '/enable/', null, 'put');
export const disableFBPersonal = (id) => () =>
  fetchApi('fb_personal/' + id + '/disable/', null, 'put');

//todo: Shopee
export const getShopee = () => () =>
  fetchApi('companies/' + DV.company.id + '/shopee/accounts/', null, 'get', null, '2.0');
export const addShopee = (data, id) => () =>
  fetchApi('companies/' + DV.company.id + '/shopee/accounts/', data, 'post', null, '2.0');
export const updateShopee = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/shopee/accounts/' + id, data, 'patch', null, '2.0');
export const deleteShopee = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/shopee/accounts/' + id, null, 'delete', null, '2.0');
export const getShopeeCaptcha = () => () =>
  fetchApi('companies/' + DV.company.id + '/shopee/captcha/', null, 'get', null, '2.0');

// Tiktok
export const getTiktokOauthLink = (data, id) => () =>
  fetchApi('companies/' + DV.company.id + '/channels/tiktok-business/generate-oauth-link/', data, 'post', null, '3.0');

//todo: Sendo
export const getSendo = () => () =>
  fetchApi('companies/' + DV.company.id + '/sendo/accounts/', null, 'get', null, '2.0');
export const addSendo = (data, id) => () =>
  fetchApi('companies/' + DV.company.id + '/sendo/accounts/', data, 'post', null, '2.0');
export const updateSendo = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/sendo/accounts/' + id, data, 'patch', null, '2.0');
export const deleteSendo = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/sendo/accounts/' + id, null, 'delete', null, '2.0');

//todo: Email
export const getEmail = () => () =>
  fetchApi('companies/' + DV.company.id + '/email/accounts/', null, 'get', null, '2.0');
export const addEmail = (data, id) => () =>
  fetchApi('companies/' + DV.company.id + '/email/accounts/', data, 'post', null, '2.0');
export const updateEmail = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/email/accounts/' + id, data, 'patch', null, '2.0');
export const deleteEmail = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/email/accounts/' + id, null, 'delete', null, '2.0');

//todo: Zalo (API v3)
export const scanZalo =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/channels/zalo/scan/', null, 'get', null, '3.0');

export const addZalo = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/channels/zalo/', data, 'post', null, '3.0');

//todo: Zalo
export const getZalo = () => () =>
  fetchApi('companies/' + DV.company.id + '/zalo/accounts/', null, 'get', null, '2.0');

export const updateZalo = (id, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/zalo/' + id + '/',
    data,
    'put',
    null,
    '3.0',
  );
export const deleteZalo = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/zalo/accounts/' + id, null, 'delete', null, '2.0');

//todo: Personal facebook
export const getPersonalFacebook = () => () =>
  fetchApi('company/' + DV.company.id + '/personalfbs/', null, 'get');
export const addPersonalFacebook = (data) => () =>
  fetchApi('company/' + DV.company.id + '/personalfb/create/', data, 'post');
export const disablePersonalFacebook = (id) => () =>
  fetchApi('personalfb/' + id + '/disable/', null, 'put');
export const enablePersonalFacebook = (id) => () =>
  fetchApi('personalfb/' + id + '/enable/', null, 'put');
export const deletePersonalFacebook = (id) => () =>
  fetchApi('personalfb/' + id + '/delete/', null, 'delete');
export const updatePersonalFacebook = (id, data) => () =>
  fetchApi('personalfb/' + id + '/update/', data, 'put');

//todo: Group Facebook
export const getFacebookGroups = (params) => () =>
  fetchApi(`/companies/${DV.company.id}/channels/fb-groups/` + params, null, 'get', null, '3.0');

export const getFacebookGroupAfterRenewToken = () => () =>
  fetchApi(`/companies/${DV.company.id}/channels/fb-groups/`, {}, 'post', null, '3.0');

export const updateFacebookGroup = (id, data) => () =>
  fetchApi(`/companies/${DV.company.id}/channels/fb-groups/${id}`, data, 'patch', null, '3.0');

export const addGroupFacebook = (data) => () =>
  fetchApi('company/' + DV.company.id + '/group/add-profile/', data, 'post');
export const getGroupFacebook = () => () =>
  fetchApi('company/' + DV.company.id + '/groups/', null, 'get');
export const enableGroupFacebook = (id) => () =>
  fetchApi('groupfb/' + id + '/enable/', null, 'put');
export const disableGroupFacebook = (id) => () =>
  fetchApi('groupfb/' + id + '/disable/', null, 'put');
export const deleteGroupFacebook = (id) => () =>
  fetchApi('groupfb/personalgroupfb/' + id + '/delete/', null, 'delete');
export const updatePersonalGroupFacebook = (id, data) => () =>
  fetchApi('groupfb/personalgroupfb/' + id + '/update/', data, 'put');
export const enablePersonalGroupFacebook = (id) => () =>
  fetchApi('groupfb/personalgroupfb/' + id + '/enable/', null, 'put');
export const disablePersonalGroupFacebook = (id) => () =>
  fetchApi('groupfb/personalgroupfb/' + id + '/disable/', null, 'put');

//todo: Customer
export const getCustomers =
  (params = null) =>
  () => {
    return fetchApi('companies/' + DV.company.id + '/inboxes/' + params, null, 'get', null, '2.0');
  };
export const updateCustomer = (id, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/inboxes/' + id + '?source=dashboard',
    data,
    'patch',
    null,
    '2.0',
  );
export const importCustomer = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/inboxes/data', data, 'post', null, '2.0');

//todo: chart
export const getSummary =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/summary/' + params);

//todo: get all channel
export const getChannels = () => () =>
  fetchApi('seller/company/' + DV.company.id + '/channels/', null, 'get');
export const getStatisticsCompanyRevenue =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/company_revenue/' + params);
export const getStatisticsSellerCustomers =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/seller_customers/' + params);
export const getStatisticsSellerRevenue =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/seller_revenue/' + params);
export const getStatisticsSellerFeedback =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/seller_feedbacks/' + params);
export const getStatisticsSellerOrders =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/seller_orders/' + params);
export const getStatisticsCompanyFeedback =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/statistics/company_feedbacks/' + params);

//todo: Automatic Scenario getRules
export const createAutomaticScenario = (formData) => () =>
  fetchApi('company/' + DV.company.id + '/automation-scenario/create/', formData, 'post');
export const getRules =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/automation-scenarios/' + params, 'get');
export const deleteAutomaticScenario = (id) => () =>
  fetchApi('automation-scenario/' + id + '/delete/', null, 'delete');
export const updateAutomaticScenario = (formData, id) => () =>
  fetchApi('automation-scenario/' + id + '/update/', formData, 'put');
export const disableAutomaticScenario = (id) => () =>
  fetchApi('automation-scenario/' + id + '/disable/', null, 'put');
export const enableAutomaticScenario = (id) => () =>
  fetchApi('automation-scenario/' + id + '/enable/', null, 'put');
export const getScenarioResults =
  (id, params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/automation-scenarios/' + id + '/messages/' + params,
      null,
      'get',
      null,
      '2.0',
    );
export const getScenario = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/automation-scenarios/' + id, null, 'get', null, '2.0');
export const retryScenario = (id) => () =>
  fetchApi('automation-scenario/' + id + '/retry/', null, 'put');
export const getScenarioDetail = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/automation-scenarios/' + id + '',
    null,
    'get',
    null,
    '2.0',
  );
export const uploadScenarioAttachments = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/automation-scenarios/attachments/',
    data,
    'post',
    null,
    '2.0',
  );

//todo: create broadcast
export const getBroadcast =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/broadcasts/' + params, null, 'get', null, '2.0');
export const getBroadcastDetail = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/broadcasts/' + id + '/', null, 'get', null, '2.0');
export const createBroadcast = (payload) => () =>
  fetchApi('companies/' + DV.company.id + '/broadcasts/', payload, 'post', null, '2.0');
export const updateBroadcast = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/broadcasts/' + id + '/', data, 'put', null, '2.0');
export const patchBroadcast = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/broadcasts/' + id + '/', data, 'patch', null, '2.0');
export const deleteBroadcast = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/broadcasts/' + id + '/', null, 'delete', null, '2.0');
export const postBroadcastAttachment = (id, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/broadcasts/' + id + '/attachments/',
    data,
    'post',
    null,
    '2.0',
  );

//todo: Sync Product
export const getSourceSyncProduct =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/product-sources/', null, 'get', null, '2.0');
export const createSourceSyncProduct = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/product-sources/', data, 'post', null, '2.0');
export const updateSourceSyncProduct = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/product-sources/' + id + '/', data, 'put', null, '2.0');
export const deleteSourceSyncProduct = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/product-sources/' + id + '/',
    null,
    'delete',
    null,
    '2.0',
  );
export const syncProductsFromSource = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/product-sources/' + id + '/sync/',
    null,
    'put',
    null,
    '2.0',
  );
export const addShopeeSource = (payload) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/product-sources/shopee-authorize/',
    payload,
    'post',
    null,
    '2.0',
  );

//todo: shipping services
export const getShipmentAccounts = () => () =>
  fetchApi('company/' + DV.company.id + '/shipment-accounts/', 'get');
export const createShipmentService = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/shipment_services/', data, 'post', null, '2.0');
export const deleteShipmentService = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/shipment_services/' + id, null, 'delete', null, '2.0');
export const updateShipmentService = (data, id) => () =>
  fetchApi('companies/' + DV.company.id + '/shipment_services/' + id, data, 'put', null, '2.0');
export const disableShipmentService = (id) => () =>
  fetchApi('company/shipment-accounts/' + id + '/disable', null, 'put');
export const enableShipmentService = (id) => () =>
  fetchApi('company/shipment-accounts/' + id + '/enable', null, 'put');

//todo: Segment
export const getSegments =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/segments/' + params, null, 'get', null, '2.0');
export const createSegment = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/segments/', data, 'post', null, '2.0');
export const deleteSegment = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/segments/' + id, null, 'delete', null, '2.0');
export const updateSegment =
  (data, id, method = 'put') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/segments/' + id + '/', data, method, null, '2.0');
export const bulkUpdateSegment =
  (data, method = 'put') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/segments/bulk-update/', data, method, null, '2.0');

//todo: Tags
export const getTags =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/tags/' + params, null, 'get', null, '2.0');
export const createTags = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/tags/', data, 'post', null, '2.0');
export const deleteTags = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/tags/' + id + '/', null, 'delete', null, '2.0');
export const updateTags = (data, id) => () =>
  fetchApi('companies/' + DV.company.id + '/tags/' + id + '/', data, 'put', null, '2.0');

//todo: pick address
export const getPickAddresses = () => () =>
  fetchApi('company/' + DV.company.id + '/pick-address/list', null, 'get');
export const addPickAddress = (data) => () =>
  fetchApi('company/' + DV.company.id + '/pick-address/create', data, 'post');
export const deletePickAddress = (id) => () =>
  fetchApi('company/pick-address/' + id + '/delete', null, 'delete');
export const updatePickAddress = (id, data) => () =>
  fetchApi('company/pick-address/' + id + '/update', data, 'put');

//todo: configurations
export const getConfigurations = () => () =>
  fetchApi('company/' + DV.company.id + '/configurations', null, 'get');
export const putConfigurations = (data) => () =>
  fetchApi('company/' + DV.company.id + '/configurations', data, 'put');
export const getListSeller =
  (params = '') =>
  () =>
    fetchApi('company/' + DV.company.id + '/staffs/' + params || '', null, 'get');
export const getSettings = () => () =>
  fetchApi('companies/' + DV.company.id + '/settings/', null, 'get', null, '3.0');
export const updateSettings = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/settings/', data, 'patch', null, '3.0');

//todo: quick reply
export const getQuickReplies = (params) => () =>
  fetchApi('companies/' + DV.company.id + '/quick-replies/' + params, null, 'get', null, '2.0');
export const createQuickReply = (payload) => () =>
  fetchApi('companies/' + DV.company.id + '/quick-replies/', payload, 'post', null, '2.0');
export const updateQuickReply = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/quick-replies/' + id, data, 'put', null, '2.0');
export const patchQuickReply = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/quick-replies/' + id, data, 'patch', null, '2.0');
export const deleteQuickReply = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/quick-replies/' + id, null, 'delete', null, '2.0');
export const postQuickReplyAttachment = (id, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/quick-replies/' + id + '/attachments/',
    data,
    'post',
    null,
    '2.0',
  );

//todo:
export const postStaffDevice = (staffId, deviceId) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/staffs/' + staffId + '/devices/',
    { device_id: deviceId },
    'post',
    null,
    '2.0',
  );

export const checkQuickRepliesCode = (code, id) => () => {
  let params = '?code=' + code;
  if (id) params += '&quick_reply_id=' + id;
  return fetchApi(
    'companies/' + DV.company.id + '/quick-replies/' + params,
    null,
    'head',
    null,
    '2.0',
  );
};

//todo: Payment Method
export const getPaymentMethods = () => () =>
  fetchApi('companies/' + DV.company.id + '/payment-methods/', {}, 'get', null, '2.0');
export const createPaymentMethod = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/payment-methods/', data, 'post', null, '2.0');
export const deletePaymentMethod = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/payment-methods/' + id, {}, 'delete', null, '2.0');
export const updatePaymentMethod = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/payment-methods/' + id, data, 'patch', null, '2.0');

//todo: Pbx
export const GetListPBX = () => () =>
  fetchApi('companies/' + DV.company.id + '/pbx/accounts/', null, 'get', null, '2.0');
export const GetPBX = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/pbx/accounts/' + id + '/', null, 'get', null, '2.0');
export const addPBX = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/pbx/accounts/', data, 'post', null, '2.0');
export const updatePBX = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/pbx/accounts/' + id + '/', data, 'put', null, '2.0');
export const patchPBX = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/pbx/accounts/' + id + '/', data, 'patch', null, '2.0');
export const deletePBX = (id) => () =>
  fetchApi('companies/' + DV.company.id + '/pbx/accounts/' + id, null, 'delete', null, '2.0');
export const getListHotlines = (params) => () =>
  fetchApi(
    'companies/' + DV.company.id + `/pbx/accounts/hotlines/?${setParamsUrl(params)}`,
    null,
    'get',
    null,
    '2.0',
  );

export const GetListCallAgents = (pbxId) => () =>
  fetchApi(`companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/`, null, 'get', null, '2.0');
export const addAgent = (pbxId, data) => () =>
  fetchApi(`companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/`, data, 'post', null, '2.0');
export const patchCallAgent = (pbxId, agentId, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/${agentId}/`,
    data,
    'patch',
    null,
    '2.0',
  );
export const deleteCallAgent = (pbxId, agentId) => () =>
  fetchApi(
    `companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/${agentId}/`,
    null,
    'delete',
    null,
    '2.0',
  );

export const addAgentStaff = (pbxId, agentId, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/${agentId}/staffs/`,
    data,
    'post',
    null,
    '2.0',
  );
export const getListAgentStaff = (pbxId, agentId) => () =>
  fetchApi(
    `companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/${agentId}/staffs/`,
    null,
    'get',
    null,
    '2.0',
  );
export const deleteListAgentStaff = (pbxId, agentId, agentStaffId) => () =>
  fetchApi(
    `companies/${DV.company.id}/pbx/accounts/${pbxId}/agents/${agentId}/staffs/${agentStaffId}/`,
    null,
    'delete',
    null,
    '2.0',
  );

//todo: Statistic
export const getTips = () => () => fetchApi('users/me/tips/', {}, 'get', null, '2.0');
export const closeTip = (id) => () =>
  fetchApi('users/me/tips/' + id + '/', {}, 'delete', null, '2.0');
export const getQuickMenus = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/staffs/' + id + '/quick-menus/',
    {},
    'get',
    null,
    '2.0',
  );

//todo: Chart
export const getOrderStatus = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/order?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getIncome = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/income?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getProductsPerformance = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/product-performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getDistributionMessageTypeEmployees = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/staff/message-type?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getPerformanceMessage = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/staff/response-performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );

export const getChannelPerformanceMessage = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/channel/response-performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getPerformanceOrder = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/staff/order-performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getPerformanceScenario = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/automation-scenario/performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getPerformanceSaleChannels = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/channel/performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getReceptionPerformance = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/staff/reception-performance?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getStaffRank = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/staff/rank?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getDistributionDirectionMessage = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/staff/message-direction?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );
export const getDistributionMessageTypeChannels = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/channel/message-type?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );

export const getCustomerSentiment = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/customer-sentiment/?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );

export const getStatisticsSummary = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/statistics/summary/?${setParamsUrl(params)}`,
    {},
    'get',
    null,
    '2.0',
  );

//TODO: Agent
export const getAgents =
  (params = '') =>
  () =>
    fetchApi('hbrc-agents/' + params, null, 'get', null, '3.0');
export const createAgent = (data) => () =>
  fetchApi('hbrc-agents/', data, 'post', null, '3.0');
export const generateAgentConnectionString =
  (agentId) =>
    () =>
      fetchApi('hbrc-agents/' + agentId + '/connection-string/', null, 'post', null, '3.0');
export const getAgentInstances =
  (agentId, params = '') =>
    () =>
      fetchApi('hbrc-agents/' + agentId + '/instances/' + params, null, 'get', null, '3.0');

export const subscribeNotification = (staffId, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/staffs/' + staffId + '/subscribe-notification/',
    data,
    'post',
    null,
    '2.0',
  );

//todo: Automatic Scenario
export const getScenarios = () => () =>
  fetchApi('companies/' + DV.company.id + '/automation-scenarios/', null, 'get', null, '2.0');
export const createScenario = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/automation-scenarios/', data, 'post', null, '2.0');
export const deleteScenario = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/automation-scenarios/' + id,
    null,
    'delete',
    null,
    '2.0',
  );
export const updateScenario = (data, id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/automation-scenarios/' + id,
    data,
    'patch',
    null,
    '2.0',
  );

//todo: Manual channel
export const getManualChannel = () => () =>
  fetchApi('companies/' + DV.company.id + '/channels/manuals/', null, 'get', null, '3.0');

export const addManualChannel = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/channels/manuals/', data, 'post', null, '3.0');

export const updateManualChannel = (manualChannelId, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/manuals/' + manualChannelId,
    data,
    'patch',
    null,
    '3.0',
  );

export const deleteManualChannel = (manualChannelId) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/manuals/' + manualChannelId,
    null,
    'delete',
    null,
    '3.0',
  );

export const importManualChannel = (manualChannelId, data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/manuals/' + manualChannelId + '/customers/import/',
    data,
    'post',
    null,
    '3.0',
  );

export const cancelUploadingManualChannel = (manualChannelId) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/manuals/' + manualChannelId + '/customers/import/',
    null,
    'delete',
    null,
    '3.0',
  );

//todo: Zns
export const getZnsTemplates =
  (params = '') =>
  () =>
    fetchApi('companies/' + DV.company.id + '/zns-templates/' + params, null, 'get', null, '3.0');

export const registerZnsTemplates = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/zns-templates/', data, 'post', null, '3.0');

export const updateZnsTemplates = (id, data) => () =>
  fetchApi('companies/' + DV.company.id + '/zns-templates/' + id + '/', data, 'patch', null, '3.0');

export const deleteZnsTemplate = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/zns-templates/' + id + '/',
    null,
    'delete',
    null,
    '3.0',
  );

export const getZnsTemplateResults = (id) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/zns-templates/' + id + '/results/',
    null,
    'get',
    null,
    '3.0',
  );

export const getZnsTemplateButtons = () => () =>
  fetchApi('companies/' + DV.company.id + '/zns-templates/buttons', null, 'get', null, '3.0');

export const uploadZnsTemplateLogo = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/zns-templates/logos/', data, 'post', null, '3.0');

//Apple business
export const getListAppleBusiness = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/channels/apple-businesses/` + params,
    null,
    'get',
    null,
    '3.0',
  );

export const getDetailAppleBusiness = (id) => () =>
  fetchApi(`companies/${DV.company.id}/channels/apple-businesses/` + id, null, 'get', null, '3.0');

export const addAppleBusiness = (data) => () =>
  fetchApi(`companies/${DV.company.id}/channels/apple-businesses/`, data, 'post', null, '3.0');

export const deleteAppleBusiness = (id) => () =>
  fetchApi(
    `companies/${DV.company.id}/channels/apple-businesses/${id}/`,
    null,
    'delete',
    null,
    '3.0',
  );

export const updateAppleBusiness = (id, data) => () =>
  fetchApi(`companies/${DV.company.id}/channels/apple-businesses/${id}/`, data, 'put', null, '3.0');

//todo: GBM
export const listGBM = (params) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/` + params,
    null,
    'get',
    null,
    '2.0',
  );
export const addGBM = (data) => () =>
  fetchApi(`companies/${DV.company.id}/google-business/accounts/`, data, 'post', null, '2.0');

export const updateGBM = (id, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${id}/`,
    data,
    'patch',
    null,
    '2.0',
  );
export const deleteGBM = (id) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${id}/`,
    null,
    'delete',
    null,
    '2.0',
  );
export const requestVerificationGBM = (id, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${id}/verification`,
    data,
    'put',
    null,
    '2.0',
  );
export const getSettingGBM = (id) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${id}/setting`,
    null,
    'get',
    null,
    '2.0',
  );
export const updateSettingGBM = (id, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${id}/setting`,
    data,
    'put',
    null,
    '2.0',
  );

// todo: GBM Location
export const getGBMLocations = () => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${DV.currentGBM.id}/locations/`,
    null,
    'get',
    null,
    '2.0',
  );
export const addGBMLocation = (data) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${DV.currentGBM.id}/locations/`,
    data,
    'post',
    null,
    '2.0',
  );
export const updateGBMLocation = (locationId, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${DV.currentGBM.id}/locations/${locationId}/`,
    data,
    'patch',
    null,
    '2.0',
  );
export const deleteGBMLocation = (locationId) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${DV.currentGBM.id}/locations/${locationId}/`,
    null,
    'delete',
    null,
    '2.0',
  );
export const requestVerificationGBMLocation = (locationId, data) => () =>
  fetchApi(
    `companies/${DV.company.id}/google-business/accounts/${DV.currentGBM.id}/locations/${locationId}/verification`,
    data,
    'put',
    null,
    '2.0',
  );

// FB Personal
export const scanFBPersonal =
  (params = '') =>
  () =>
    fetchApi(
      'companies/' + DV.company.id + '/channels/fb-personals/scan/',
      null,
      'get',
      null,
      '3.0',
    );

export const addFBPersonal = (data) => () =>
  fetchApi('companies/' + DV.company.id + '/channels/fb-personals/', data, 'post', null, '3.0');
export const getAuthorizedFanpages = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/fb-pages/authorized-pages/',
    data,
    'post',
    null,
    '3.0',
  );
export const reauthorizeFanpages = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/fb-pages/authorized-pages/',
    data,
    'post',
    null,
    '3.0',
  );

export const enableFanpages = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/fb-pages/enable-pages/',
    data,
    'post',
    null,
    '3.0',
  );

export const unlockPbx = (id, data) => async () =>
  fetchApi(`companies/${DV.company.id}/pbx/accounts/${id}/`, data, 'patch', null, '2.0');

export const getAuthorizedInstagrams = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/instagrams/authorized-channels/',
    data,
    'post',
    null,
    '3.0',
  );

export const enableInstagrams = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/instagrams/enable-channels/',
    data,
    'post',
    null,
    '3.0',
  );

export const reauthorizeInstagrams = (data) => () =>
  fetchApi(
    'companies/' + DV.company.id + '/channels/instagrams/authorized-channels/',
    data,
    'post',
    null,
    '3.0',
  );

// OAuth2 Application

export const installApplication = (clientId, companyId, responseType) =>
  fetchApi(
    'applications/install/',
    {
      client_id: clientId,
      company_id: companyId,
      response_type: responseType,
    },
    'post',
    null,
    '3.0',
  );

export const uninstallApplication = (clientId) =>
  fetchApi(
    'applications/uninstall/',
    {
      client_id: clientId,
      company_id: DV.company.id,
    },
    'post',
    null,
    '3.0',
  );
