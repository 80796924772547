import { t } from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import Routes from 'router/routes';
import { getAgentName, handleApi } from '../../../helper/helper';
import { createAgent } from "../../../api/api";
import _ from "lodash";
import Button from "../../../MUI_Kit/components/CustomButtons/Button";
import DV from "../../../variables/DV";
import { showErrorSnackbar } from "@doopage/react-ui-kit";

class CreateAgentModal extends BaseModal {
  constructor(props) {
    super(props);
    const params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.agent.path);
    this.state = {
      error: null,
      loading: false,
      open: true,
      id: null,
      title: t`Tạo Agent`,
      name: '',
      ...params
    };

    this.refreshOnClose = true;
    this.goPathWhenClose = Routes.agent.path;
  }

  close = () => {
    const { history } = this.props;
    this.setState({ open: false });
    this.redirectTo(this.goPathWhenClose);
  };

  handleSubmit = async () => {
    const { name } = this.state;
    this.setState({ loading: true });
    const { success, data, error } = await handleApi(createAgent({ name }));
    this.setState({ loading: false });
    if (success) {
      DV.showNotify(t`Đã tạo Agent thành công`);
      this.redirectToModal(Routes.agent.detail.path.replace(':id', data.id), { id: data.id, name: data.name });
    } else {
      showErrorSnackbar(error);
    }
  }


  renderFooter() {
    const { name, loading } = this.state;
    return (<Button onClick={this.handleSubmit} disabled={!name} color="info" loading={loading}>
      {t`Hoàn tất`}
    </Button>)
  }

  renderBody() {
    const { name } = this.state;
    return <>
      <CustomInput
        required
        labelText={t`Tên Agent`}
        inputProps={{
          type: 'text',
          value: name,
          onChange: (e) => this.setState({ name: e.target.value })
        }}
      />
    </>;
  }

}

export default CreateAgentModal;
