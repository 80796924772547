import { t } from '@lingui/macro';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV.js';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import BaseModal from '../BaseComponent/BaseModal';
import { handleApi } from 'helper/helper';
import { setState } from 'config/Core';
import { addZalo, getAgentInstances, getAgents, updateZalo } from 'api/api';
import { Select, Space } from "@doopage/react-ui-kit";

class ZaloModal extends BaseModal {
  constructor(props) {
    super(props);
    const { hbrc_agent_id, hbrc_browser_instance_id } = this.getDataFromRouter()
    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      agents: [],
      loadingAgents: false,
      selectedAgentId: hbrc_agent_id || '',
      instances: [],
      loadingInstances: false,
      selectedInstanceId: hbrc_browser_instance_id || '',

    };
    this.goPathWhenClose = Routes.channel.path;
  }

  getTitle() {
    const { id } =this.state;
    return id ? t`Cập nhật Zalo cá nhân` : t`Thêm Zalo cá nhân`;
  }

  add = async (instanceId) => {
    await setState(this)({loading: true});
    let { success, error } = await handleApi(addZalo({ hbrc_browser_instance_id: instanceId }));
    await setState(this)({loading: false});
    if (success) {
      this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  update = async (zaloId, instanceId) => {
    await setState(this)({ loading: true });
    let { success, error } = await handleApi(updateZalo(zaloId, { hbrc_browser_instance_id: instanceId }));
    await setState(this)({ loading: false });
    if (success) {
      this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  getInstances = async (selectedAgentId) => {
    this.setState({ loadingInstances: true });
    const { success, data } = await handleApi(getAgentInstances(selectedAgentId));
    this.setState({ loadingInstances: false });
    if (success) {
      this.setState({ instances: data });
    } else {
      DV.showNotify(t`Không thể lấy danh sách instance`, 'danger');
    }
  }


  renderBody() {
    const { agents, selectedAgentId, instances, selectedInstanceId } = this.state;

    return (<Space direction="vertical" spaceHeight={10}>
      <div style={{ fontSize: 13, color: '#555' }}>
        {t`Kênh này sử dụng Agent (một phần mềm mã nguồn mở cài đặt trên máy tính). Xem hướng dẫn sử dụng Agent`}
        &nbsp;
        <a href={'https://how.doopage.com/thao-tac-danh-cho-quan-ly/quan-ly/agent'} target="_blank"
           style={{ textDecoration: 'underline' }}>{t`tại đây`}</a>.
      </div>
      <Select
        label={t`Agent`}
        options={agents}
        value={selectedAgentId}
        onChange={(e) => {
          const newAgentId = e.target.value;
          this.setState({ selectedAgentId: newAgentId, selectedInstanceId: '' });
          if (newAgentId) {
            this.getInstances(newAgentId);
          }
        }}
        fullWidth
        style={{ margin: 0, marginBottom: 10 }}
      />
      <Select
        label={t`Instance`}
        options={instances}
        value={selectedInstanceId}
        onChange={(e) => {
          const newInstanceId = e.target.value;
          this.setState({ selectedInstanceId: newInstanceId });
        }}
        fullWidth
        style={{ margin: 0 }}
      />
    </Space>);
  }

  renderFooter() {
    const { loading } = this.state;
    return [
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }

  handleSubmit = async () => {
    const { selectedInstanceId, id: zaloId } = this.state;
    if (!selectedInstanceId) {
      DV.showNotify(t`Vui lòng chọn instance`, 'danger');
      return;
    }
    if (zaloId) {
      await this.update(zaloId, selectedInstanceId);
    } else {
      await this.add(selectedInstanceId);
    }
  };

  async componentDidMount() {
    this.setState({ loadingAgents: true })
    const { success, data } = await handleApi(getAgents());
    this.setState({ loadingAgents: false })
    if (success) {
      this.setState({ agents: data.map(agent => ({ id: agent.id, name: agent.name })) });
    } else {
      DV.showNotify(t`Không thể lấy danh sách agent`, 'danger');
    }

    if (this.state.selectedAgentId) {
      this.getInstances(this.state.selectedAgentId);
    }
  }


}

export default ZaloModal;
