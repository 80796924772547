import ShipmentServiceModal from 'component/Modal/AddShipmentServiceModal.js';
import EmailModal from 'component/Modal/EmailModal.js';
import GBMLocationModal from 'component/Modal/GBM/GBMLocationModal';
import GBMSettingModal from 'component/Modal/GBM/GBMSettingModal';
import CompensationModal from 'component/Modal/Order/CompensationModal.js';
import ExchangeProductModal from 'component/Modal/Order/ExchangeProductModal.js';
import OrderModal from 'component/Modal/Order/OrderModal.js';
import ReceiptOrderModal from 'component/Modal/Order/ReceiptOrderModal';
import ReturnProductModal from 'component/Modal/Order/ReturnProductModal.js';
import ViewOrderModal from 'component/Modal/Order/ViewOrderModal.js';
import PbxUnlockModal from 'component/Modal/PbxUnlockModal';
import VariantModal from 'component/Modal/Product/VariantModal.js';
import RedirectToShopeeModal from 'component/Modal/RedirectToShopeeModal';
import RedirectToTopshipModal from 'component/Modal/RedirectToTopshipModal.js';
import SendoModal from 'component/Modal/SendoModal.js';
import ChangeCompanyInfoModal from 'component/Modal/Setting/ChangeCompanyInfoModal.js';
import ShopeeModal from 'component/Modal/ShopeeModal.js';
import SourceSyncProductModal from 'component/Modal/SourceSyncProduct/SourceSyncProductsModal.js';
import InvitationCodeModal from 'component/Modal/Staff/InvitationCodeModal.js';
import InvitationLinkModal from 'component/Modal/Staff/InvitationLinkModal.js';
import SendInvitationEmail from 'component/Modal/Staff/SendInvitationEmail.js';
import SyncOrderShopeeModal from 'component/Modal/SyncOrderShopeeModal';
import TagProducts from 'component/Modal/TagProducts/TagProducts.js';
import {
  Loadable_AddQuickReply,
  Loadable_Agent,
  Loadable_Attribute,
  Loadable_Broadcast,
  Loadable_BroadcastDetail,
  Loadable_CallAgents,
  Loadable_Center,
  Loadable_Channel,
  Loadable_Company,
  Loadable_Credit,
  Loadable_Customer,
  Loadable_DetailChart,
  Loadable_Email,
  Loadable_Empty,
  Loadable_Fanpage,
  Loadable_GBM,
  Loadable_GBMLocations,
  Loadable_Graphs,
  Loadable_Group,
  Loadable_Instagram,
  Loadable_Integrate_API,
  Loadable_JoinCompany,
  Loadable_Login,
  Loadable_Manual_Channels,
  Loadable_Order,
  Loadable_PaymentHistory,
  Loadable_PaymentMethod,
  Loadable_PBX,
  Loadable_Personal,
  Loadable_PickupAddress,
  Loadable_Product,
  Loadable_Public_Subscription,
  Loadable_QuickReply,
  Loadable_Scenario,
  Loadable_Scenario_deprecated,
  Loadable_ScenarioDetail,
  Loadable_Segment,
  Loadable_Sendo,
  Loadable_Settings,
  Loadable_ShipmentServices,
  Loadable_Shopee,
  Loadable_Staff,
  Loadable_Statistic,
  Loadable_SubscriptionManagementV2,
  Loadable_SyncProducts,
  Loadable_Tag,
  Loadable_Variant,
  Loadable_Website,
  Loadable_ZaloOA,
  Loadable_Zns,
  Loadable_Apple_Business,
  Loadable_OAuth2,
  Loadable_Integrate_Application,
  Loadable_Applications,
  Loadable_KiotVietSetting,
} from 'config/LoadableConfig';
import FanpageSelectModal from 'screen/Channel/Fanpage/modals/FanpageSelectModal';
import ScenarioResultsModal from 'screen/Scenario/components/ScenarioResultsModal';
import ScenarioActionModal from 'screen/ScenarioFlow/modals/ScenarioActionModal';

import AddWebsiteModal from 'screen/Channel/Website/components/AddWebsiteModal';
import ViewWebsiteModal from 'screen/Channel/Website/components/ViewWebsiteModal';
import ViewAgentModal from 'screen/Channel/Agent/ViewAgentModal';
import ViewWebsiteSettingModal from 'screen/Channel/Website/components/ViewWebsiteSettingModal';
import { ALL_PERMISSION, PERMISSION_NAME } from 'variables/staticValue';
import ImportModal from '../component/ImportExportFile/ImportModal';
import AddManualChannelModal from '../component/Modal/AddManualChannel';
import AttributeModal from '../component/Modal/AttributeModal';
import CustomerModal from '../component/Modal/Customer/CustomerModal';
import GBMModal from '../component/Modal/GBM/GBMModal';
import GBMVerificationModal from '../component/Modal/GBM/GBMVerificationModal';
import ImportManualChannelModal from '../component/Modal/ImportManualChannel';
import PaymentMethodModal from '../component/Modal/PaymentMethodModal';
import PbxModal from '../component/Modal/PbxModal/PbxModal';
import PersonalModal from '../component/Modal/PersonalModal';
import PickAddressModal from '../component/Modal/PickAddressModal';
import ProductModal from '../component/Modal/Product/ProductModal';
import SegmentModal from '../component/Modal/SegmentModal';
import TagModal from '../component/Modal/TagModal';
import PreviewZnsTemplateModal from '../component/Modal/Zns/PreviewZnsTemplate';
import RegisterZnsTemplateModal from '../component/Modal/Zns/RegisterZnsTemplate/RegisterZnsTemplateModal';
import ZnsTemplateResultsModal from '../component/Modal/Zns/ZnsTemplateResultsModal';
import FanpageConfigModal from '../screen/Channel/Fanpage/modals/FanpageConfigModal';
import InstagramConfigModal from '../screen/Channel/Instagram/modals/InstagramConfigModal';
import ThanksModal from '../screen/Payment/components/ThanksModal';
import ScenarioModal from '../screen/ScenarioFlow/modals/ScenarioConditionModal';
import ScenarioConditionModal from '../screen/ScenarioFlow/modals/ScenarioConditionModal';
import ListCharts from '../screen/Statitic/Charts/ListCharts';
import AppleBusinessModal from '../component/Modal/AppleBusiness/AppleBusinessModal';
import InstagramSelectModal from '../screen/Channel/Instagram/modals/InstagramSelectModal';
import AddTelegramModal from '../screen/Channel/Telegram/components/AddTelegramModal';
import PbxAgentModal from '../component/Modal/PbxAgentModal';
import ModalEnableGroup from '../screen/Channels/ModalEnableGroup';
import PersonalZaloModal from '../component/Modal/PersonalZaloModal';
import RedirectToTiktokModal from "../component/Modal/RedirectToTiktokModal";
import CreateAgentModal from "../screen/Channel/Agent/CreateAgentModal";

const { stock_keeper, manager, marketer, owner, seller } = PERMISSION_NAME;

export const parentPath = '/dashboard';
export const createPath = '/create';
export const updatePath = '/update/:id';
export const detailPath = '/detail/:id';

export const getUpdatePath = (id) => '/update/' + id;
export const getDetailPath = (id) => '/detail/' + id;

const getChartDetailRoute = () => {
  const routes = {};
  const listCharts = ListCharts();
  Object.keys(listCharts).forEach((key) => {
    const { hasDetail } = listCharts[key];
    if (hasDetail) {
      routes[key] = {
        permission: [owner, manager],
        path: `${parentPath}/statistic/${key}`,
        component: Loadable_DetailChart,
        props: { ...listCharts[key], code: key },
      };
    }
  });
  return routes;
};

export default {
  channel: {
    permission: [owner, marketer, manager],
    path: parentPath + '/channel',
    component: Loadable_Channel,
    instagram: {
      permission: [owner, marketer, manager],
      add: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/instagram/add',
        component: InstagramSelectModal,
      },
      config: {
        permission: [owner],
        path: parentPath + '/channel/instagram/config/:id',
        component: InstagramConfigModal,
      },
    },

    fanpage: {
      permission: [owner, marketer, manager],
      add: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/fanpage/add',
        component: FanpageSelectModal,
      },
      config: {
        permission: [owner],
        path: parentPath + '/channel/fanpage/config/:id',
        component: FanpageConfigModal,
      },
    },

    group: {
      permission: [owner, marketer, manager],
      add: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/group/add',
        component: ModalEnableGroup,
      },
    },

    personal: {
      permission: [owner, manager, marketer],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/personal' + createPath,
        component: PersonalModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/personal' + updatePath,
        component: PersonalModal,
      },
      detail: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/personal' + detailPath,
        component: PersonalModal,
      },
    },

    zalo: {
      permission: [owner, marketer, manager],
      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/personal-zalo' + createPath,
        component: PersonalZaloModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/personal-zalo' + updatePath,
        component: PersonalZaloModal,
      },
    },

    znsTemplates: {
      permission: [owner],

      register: {
        permission: [owner],
        path: parentPath + '/channel/zalo/zns-templates/register',
        component: RegisterZnsTemplateModal,
      },
      update: {
        permission: [owner],
        path: parentPath + '/channel/zalo/zns-templates' + updatePath,
        component: RegisterZnsTemplateModal,
      },
      preview: {
        permission: [owner],
        path: parentPath + '/channel/zalo/zns-templates/preview/:id',
        component: PreviewZnsTemplateModal,
      },
      results: {
        permission: [owner],
        path: parentPath + '/channel/zalo/zns-templates/results/:id',
        component: ZnsTemplateResultsModal,
      },
    },

    website: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/website' + createPath,
        component: ViewWebsiteSettingModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/website' + updatePath,
        component: AddWebsiteModal,
      },
      detail: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/website' + detailPath,
        component: ViewWebsiteModal,
      },
      config: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/website/config/:id',
        component: ViewWebsiteSettingModal,
      },
    },

    telegram: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/telegram' + createPath,
        component: AddTelegramModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/telegram' + updatePath,
        component: AddTelegramModal,
      },
    },
    shopee: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/shopee' + createPath,
        component: ShopeeModal,
      },
      authorize: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/shopee/authorize',
        component: RedirectToShopeeModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/shopee' + updatePath,
        component: ShopeeModal,
      },
      syncOrder: {
        permission: [owner],
        path: parentPath + '/channel/shopee/sync-order',
        component: SyncOrderShopeeModal,
      },
    },
    tiktok: {
      permission: [owner, marketer, manager],
      authorize: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/tiktok/authorize',
        component: RedirectToTiktokModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/tiktok' + updatePath,
        component: ShopeeModal,
      },
    },

    manual: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/manual-channel' + createPath,
        component: AddManualChannelModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/manual-channel' + updatePath,
        component: AddManualChannelModal,
      },
      upload: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/manual-channel/import/:id',
        component: ImportManualChannelModal,
      },
    },

    sendo: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/sendo' + createPath,
        component: SendoModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/sendo' + updatePath,
        component: SendoModal,
      },
    },

    email: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/email' + createPath,
        component: EmailModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/email' + updatePath,
        component: EmailModal,
      },
    },

    gbm: {
      permission: [owner, manager, marketer],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/gbm' + createPath,
        component: GBMModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/gbm' + updatePath,
        component: GBMModal,
      },
      verification: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/gbm/verification',
        component: GBMVerificationModal,
      },
      setting: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/gbm/setting',
        component: GBMSettingModal,
      },
    },

    gbmLocation: {
      permission: [owner, marketer, manager],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/gbm-location' + createPath,
        component: GBMLocationModal,
      },
    },

    appleBusinesses: {
      permission: [owner, manager, marketer],

      create: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/apple-business' + createPath,
        component: AppleBusinessModal,
      },
      update: {
        permission: [owner, marketer, manager],
        path: parentPath + '/channel/apple-business' + updatePath,
        component: AppleBusinessModal,
      },
    },

    pbx: {
      permission: [owner, manager, stock_keeper, marketer],
      create: {
        permission: [owner, manager, stock_keeper, marketer],
        path: parentPath + '/channel/pbx' + createPath,
        component: PbxModal,
      },
      update: {
        permission: [owner, manager, stock_keeper, marketer],
        path: parentPath + '/channel/pbx' + updatePath,
        component: PbxModal,
      },
      unlock: {
        permission: [owner, manager, stock_keeper, marketer],
        path: parentPath + '/channel/pbx/unlock/:id',
        component: PbxUnlockModal,
      },
      manageAgents: {
        permission: [owner, manager, stock_keeper, marketer],
        path: parentPath + '/channel/pbx/agents/:id',
        component: PbxAgentModal,
      },
    },
  },
  applications: {
    permission: [owner, manager],
    path: parentPath + '/applications',
    component: Loadable_Applications,
    kiotviet: {
      permission: [owner, manager],
      path: parentPath + '/applications/kiotviet',
      component: Loadable_KiotVietSetting,
    },
  },
  fanpage: {
    permission: [owner, marketer, manager],
    path: parentPath + '/fanpage',
    component: Loadable_Fanpage,
    config: {
      permission: [owner],
      path: parentPath + '/fanpage/config/:id',
      component: FanpageConfigModal,
    },
  },
  group: {
    permission: [owner, marketer, manager],
    path: parentPath + '/group',
    component: Loadable_Group,
  },
  zalo: {
    permission: [owner, marketer, manager],
    path: parentPath + '/zalo',
    component: Loadable_ZaloOA,
  },
  znsTemplates: {
    permission: [owner],
    path: parentPath + '/zalo/zns-templates',
    component: Loadable_Zns,
    register: {
      permission: [owner],
      path: parentPath + '/zalo/zns-templates/register',
      component: RegisterZnsTemplateModal,
    },
    update: {
      permission: [owner],
      path: parentPath + '/zalo/zns-templates' + updatePath,
      component: RegisterZnsTemplateModal,
    },
    preview: {
      permission: [owner],
      path: parentPath + '/zalo/zns-templates/preview/:id',
      component: PreviewZnsTemplateModal,
    },
    results: {
      permission: [owner],
      path: parentPath + '/zalo/zns-templates/results/:id',
      component: ZnsTemplateResultsModal,
    },
  },
  instagram: {
    permission: [owner, marketer, manager],
    path: parentPath + '/instagram',
    component: Loadable_Instagram,
    config: {
      permission: [owner],
      path: parentPath + '/instagram/config/:id',
      component: InstagramConfigModal,
    },
  },
  website: {
    permission: [owner, marketer, manager],
    path: parentPath + '/website',
    component: Loadable_Website,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/website' + createPath,
      component: AddWebsiteModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/website' + updatePath,
      component: AddWebsiteModal,
    },
    detail: {
      permission: [owner, marketer, manager],
      path: parentPath + '/website' + detailPath,
      component: ViewWebsiteModal,
    },
    setting: {
      permission: [owner, marketer, manager],
      path: parentPath + '/website' + '/setting/:id',
      component: ViewWebsiteSettingModal,
    },
  },
  personal: {
    permission: [owner, manager, marketer],
    path: parentPath + '/personal',
    component: Loadable_Personal,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/personal' + createPath,
      component: PersonalModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/personal' + updatePath,
      component: PersonalModal,
    },
    detail: {
      permission: [owner, marketer, manager],
      path: parentPath + '/personal' + detailPath,
      component: PersonalModal,
    },
  },
  shopee: {
    permission: [owner, marketer, manager],
    path: parentPath + '/shopee',
    component: Loadable_Shopee,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/shopee' + createPath,
      component: ShopeeModal,
    },
    authorize: {
      permission: [owner, marketer, manager],
      path: parentPath + '/shopee' + '/authorize',
      component: RedirectToShopeeModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/shopee' + updatePath,
      component: ShopeeModal,
    },
    syncOrder: {
      permission: [owner],
      path: parentPath + '/shopee/sync-order',
      component: SyncOrderShopeeModal,
    },
  },
  manual: {
    permission: [owner, marketer, manager],
    path: parentPath + '/manual-channel',
    component: Loadable_Manual_Channels,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/manual-channel' + createPath,
      component: AddManualChannelModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/manual-channel' + updatePath,
      component: AddManualChannelModal,
    },
    import: {
      permission: [owner, marketer, manager],
      path: parentPath + '/manual-channel/import',
      component: ImportManualChannelModal,
    },
  },
  sendo: {
    permission: [owner, marketer, manager],
    path: parentPath + '/sendo',
    component: Loadable_Sendo,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/sendo' + createPath,
      component: SendoModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/sendo' + updatePath,
      component: SendoModal,
    },
  },
  email: {
    permission: [owner, marketer, manager],
    path: parentPath + '/email',
    component: Loadable_Email,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/email' + createPath,
      component: EmailModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/email' + updatePath,
      component: EmailModal,
    },
  },
  // personalZalo: {
  //   permission: [owner, marketer, manager],
  //   path: parentPath + '/personal-zalo',
  //   component: Loadable_PersonalZalo,
  //   create: {
  //     permission: [owner, marketer, manager],
  //     path: parentPath + '/personal-zalo' + createPath,
  //     component: PersonalZaloModal,
  //   },
  //   update: {
  //     permission: [owner, marketer, manager],
  //     path: parentPath + '/personal-zalo' + updatePath,
  //     component: PersonalZaloModal,
  //   },
  // },
  staff: {
    permission: [owner, manager],
    path: parentPath + '/staff',
    component: Loadable_Staff,
    createInvitationEmails: {
      permission: [owner, manager],
      path: parentPath + '/staff/invitation-emails',
      component: SendInvitationEmail,
    },
    createInvitationLink: {
      permission: [owner, manager],
      path: parentPath + '/staff/invitation-link',
      component: InvitationLinkModal,
    },
    createInvitationCode: {
      permission: [owner, manager],
      path: parentPath + '/staff/invitation-code',
      component: InvitationCodeModal,
    },
  },
  agent: {
    permission: [owner, manager],
    path: parentPath + '/agent',
    component: Loadable_Agent,
    detail: {
      permission: [owner, marketer, manager],
      path: parentPath + '/agent' + detailPath,
      component: ViewAgentModal,
    },
    create: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order' + createPath,
      component: CreateAgentModal,
    },
  },
  order: {
    permission: [owner, stock_keeper],
    path: parentPath + '/order',
    component: Loadable_Order,
    tagProducts: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order/tag-products',
      component: TagProducts,
    },
    create: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order' + createPath,
      component: OrderModal,
    },
    detail: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order' + detailPath,
      component: ViewOrderModal,
    },
    compensation: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order/compensation',
      component: CompensationModal,
    },
    returnRefund: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order/return-refund',
      component: ReturnProductModal,
    },
    exchange: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order/exchange',
      component: ExchangeProductModal,
    },
    print_bill_batch: {
      permission: [owner, stock_keeper],
      path: parentPath + '/order/print-bill-batch',
      component: ReceiptOrderModal,
    },
  },
  customer: {
    permission: [owner, marketer, manager],
    path: parentPath + '/customer',
    component: Loadable_Customer,
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/customer' + updatePath,
      component: CustomerModal,
    },
    import: {
      permission: [owner, marketer, manager],
      path: parentPath + '/customer/import',
      component: ImportModal,
    },
  },
  tag: {
    permission: [owner, marketer, manager],
    path: parentPath + '/tag',
    component: Loadable_Tag,
    create: {
      permission: [owner, marketer],
      path: parentPath + '/tag' + createPath,
      component: TagModal,
    },
    update: {
      permission: [owner, marketer],
      path: parentPath + '/tag' + updatePath,
      component: TagModal,
    },
  },
  segment: {
    permission: [owner, marketer],
    path: parentPath + '/segment',
    component: Loadable_Segment,
    create: {
      permission: [owner, marketer],
      path: parentPath + '/segment' + createPath,
      component: SegmentModal,
    },
    update: {
      permission: [owner, marketer],
      path: parentPath + '/segment' + updatePath,
      component: SegmentModal,
    },
  },
  statistic: {
    permission: [owner, manager],
    path: parentPath + '/statistic',
    ...getChartDetailRoute(),
    overview: {
      permission: [owner, manager],
      path: parentPath + '/statistic/overview',
      component: Loadable_Statistic,
    },
    sales: {
      permission: [owner, manager],
      path: parentPath + '/statistic/sales',
      component: Loadable_Graphs,
    },
    response: {
      permission: [owner, manager],
      path: parentPath + '/statistic/response',
      component: Loadable_Graphs,
    },
    scenario: {
      permission: [owner, manager],
      path: parentPath + '/statistic/scenario',
      component: Loadable_Graphs,
    },
    channel: {
      permission: [owner, manager],
      path: parentPath + '/statistic/channel',
      component: Loadable_Graphs,
    },
    customer: {
      permission: [owner, manager],
      path: parentPath + '/statistic/customer',
      component: Loadable_Graphs,
    },
  },
  // payment: {
  //     permission: [owner, manager],
  //     path: parentPath + '/subscriptions/old',
  //     component: Loadable_Payment,
  //     thanks: {
  //         permission: [owner, manager],
  //         path: parentPath + '/subscriptions/thanks',
  //         component: ThanksModal,
  //     },
  //     thanks2: {
  //         permission: [owner, manager],
  //         path: parentPath + '/subscriptions/thanks/step2',
  //         component: ThanksModal,
  //     },
  // },
  subscriptions: {
    permission: [owner, manager],
    path: parentPath + '/subscriptions',
    component: Loadable_SubscriptionManagementV2,
    thanks: {
      permission: [owner, manager],
      path: parentPath + '/subscriptions/thanks',
      component: ThanksModal,
    },
    thanks2: {
      permission: [owner, manager],
      path: parentPath + '/subscriptions/thanks/step2',
      component: ThanksModal,
    },
  },
  paymentHistory: {
    permission: [owner, manager],
    path: parentPath + '/history',
    component: Loadable_PaymentHistory,
  },
  credit: {
    permission: [owner],
    path: parentPath + '/credit',
    component: Loadable_Credit,
  },
  integrateApplication: {
    permission: [owner, manager],
    path: parentPath + '/applications',
    component: Loadable_Integrate_Application,
  },
  integrateAPI: {
    permission: [owner],
    path: parentPath + '/integrate-api',
    component: Loadable_Integrate_API,
  },
  variant: {
    permission: [owner, manager, stock_keeper, marketer],
    path: parentPath + '/variant',
    component: Loadable_Variant,
    create: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/variant' + createPath,
      component: VariantModal,
    },
    update: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/variant' + updatePath,
      component: VariantModal,
    },
  },
  product: {
    permission: [owner, manager, stock_keeper, marketer],
    path: parentPath + '/product',
    component: Loadable_Product,
    import: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/product/import',
      component: ImportModal,
    },
    create: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/product' + createPath,
      component: ProductModal,
    },
    update: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/product' + updatePath,
      component: ProductModal,
    },
  },
  attribute: {
    permission: [owner, stock_keeper],
    path: parentPath + '/attribute',
    component: Loadable_Attribute,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/attribute' + createPath,
      component: AttributeModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/attribute' + updatePath,
      component: AttributeModal,
    },
  },
  scenario: {
    permission: [owner, marketer],
    path: parentPath + '/scenario',
    component: Loadable_Scenario,
    condition: {
      permission: [owner, marketer],
      path: `${parentPath}/scenario/:id/condition`,
      component: ScenarioConditionModal,
    },
    action: {
      permission: [owner, marketer],
      path: `${parentPath}/scenario/:id/action`,
      component: ScenarioActionModal,
    },
    results: {
      permission: [owner, marketer],
      path: parentPath + '/scenario/results',
      component: ScenarioResultsModal,
    },
  },
  scenario_deprecated: {
    permission: [owner, marketer],
    path: parentPath + '/scenario_deprecated',
    component: Loadable_Scenario_deprecated,
    create: {
      permission: [owner, marketer],
      path: parentPath + '/scenario_deprecated' + createPath,
      component: Loadable_ScenarioDetail,
    },
    updateModal: {
      permission: [owner, marketer],
      path: `${parentPath}/scenario_deprecated/flow/:id`,
      component: ScenarioModal,
    },
    update: {
      permission: [owner, marketer],
      path: parentPath + '/scenario_deprecated' + updatePath,
      component: Loadable_ScenarioDetail,
    },
    detail: {
      permission: [owner, marketer],
      path: parentPath + '/scenario_deprecated' + detailPath,
      component: Loadable_ScenarioDetail,
    },
  },
  quickReply: {
    permission: [owner, marketer, seller],
    path: parentPath + '/quick-reply',
    component: Loadable_QuickReply,
    createOld: {
      permission: [owner, marketer],
      path: parentPath + '/quick-reply' + createPath + '/old',
      component: Loadable_AddQuickReply,
    },
    create: {
      permission: [owner, marketer],
      path: parentPath + '/quick-reply' + createPath,
      // component: QuickRelyModal,
      component: Loadable_AddQuickReply,
    },
    update: {
      permission: [owner, marketer],
      path: parentPath + '/quick-reply' + updatePath,
      component: Loadable_AddQuickReply,
    },
    detail: {
      permission: [owner, marketer, seller],
      path: parentPath + '/quick-reply' + detailPath,
      component: Loadable_AddQuickReply,
    },
  },
  broadcast: {
    permission: [owner, marketer, seller],
    path: parentPath + '/broadcast',
    component: Loadable_Broadcast,
    create: {
      permission: [owner, marketer],
      path: parentPath + '/broadcast' + createPath,
      component: Loadable_BroadcastDetail,
    },
    update: {
      permission: [owner, marketer],
      path: parentPath + '/broadcast' + updatePath,
      component: Loadable_BroadcastDetail,
    },
    detail: {
      permission: [owner, marketer],
      path: parentPath + '/broadcast' + detailPath,
      component: Loadable_BroadcastDetail,
    },
  },
  syncProducts: {
    permission: [owner, manager],
    path: parentPath + '/sync-product',
    component: Loadable_SyncProducts,
    create: {
      permission: [owner, manager],
      path: parentPath + '/sync-product' + createPath,
      component: SourceSyncProductModal,
    },
    update: {
      permission: [owner, marketer],
      path: parentPath + '/sync-product' + updatePath,
      component: SourceSyncProductModal,
    },
  },
  shipmentServices: {
    permission: [owner, manager],
    path: parentPath + '/shipment-services',
    component: Loadable_ShipmentServices,
    create: {
      permission: [owner, manager],
      path: parentPath + '/shipment-services' + createPath,
      component: ShipmentServiceModal,
    },
    update: {
      permission: [owner, manager],
      path: parentPath + '/shipment-services' + updatePath,
      component: ShipmentServiceModal,
    },
  },
  pickupAddress: {
    permission: [owner, stock_keeper],
    path: parentPath + '/pickup-address',
    component: Loadable_PickupAddress,
    create: {
      permission: [owner, stock_keeper],
      path: parentPath + '/pickup-address' + createPath,
      component: PickAddressModal,
    },
    update: {
      permission: [owner, stock_keeper],
      path: parentPath + '/pickup-address' + updatePath,
      component: PickAddressModal,
    },
  },
  settings: {
    permission: [owner, manager],
    path: parentPath + '/settings',
    component: Loadable_Settings,
    companyInfo: {
      permission: [owner, manager],
      path: parentPath + '/settings/company-info',
      component: ChangeCompanyInfoModal,
    },
  },
  paymentMethod: {
    permission: [owner, manager],
    path: parentPath + '/payment-method',
    component: Loadable_PaymentMethod,
    create: {
      permission: [owner, manager],
      path: parentPath + '/payment-method' + createPath,
      component: PaymentMethodModal,
    },
    update: {
      permission: [owner, manager],
      path: parentPath + '/payment-method' + updatePath,
      component: PaymentMethodModal,
    },
  },
  pbx: {
    permission: [owner, manager, stock_keeper, marketer],
    path: parentPath + '/pbx',
    component: Loadable_PBX,
    create: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/pbx' + createPath,
      component: PbxModal,
    },
    update: {
      permission: [owner, manager, stock_keeper, marketer],
      path: parentPath + '/pbx' + updatePath,
      component: PbxModal,
    },
  },
  callAgents: {
    permission: [owner, manager, stock_keeper, marketer],
    path: parentPath + '/call-agents',
    component: Loadable_CallAgents,
  },
  center: {
    path: parentPath + '/center',
    permission: ALL_PERMISSION,
    component: Loadable_Center,
  },
  empty: {
    path: parentPath + '/empty',
    permission: ALL_PERMISSION,
    component: Loadable_Empty,
  },
  company: {
    path: '/company',
    permission: ALL_PERMISSION,
    component: Loadable_Company,
  },
  login: {
    path: '/login',
    permission: ALL_PERMISSION,
    component: Loadable_Login,
  },
  loginByPassword: {
    path: '/login-by-password',
    permission: ALL_PERMISSION,
    component: Loadable_Login,
  },
  joinCompany: {
    path: '/join-company',
    permission: ALL_PERMISSION,
    component: Loadable_JoinCompany,
  },
  redirectToTopshipModal: {
    permission: [owner, manager],
    path: parentPath + '/redirect-to-topship',
    component: RedirectToTopshipModal,
  },
  publicSubscription: {
    path: '/public-subscription',
    permission: ALL_PERMISSION,
    component: Loadable_Public_Subscription,
  },
  scenarioView: {
    path: '/scenario-view',
    permission: ALL_PERMISSION,
    component: Loadable_Scenario,
  },
  gbm: {
    permission: [owner, manager, marketer],
    path: parentPath + '/gbm',
    component: Loadable_GBM,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/gbm' + createPath,
      component: GBMModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/gbm' + updatePath,
      component: GBMModal,
    },
    verification: {
      permission: [owner, marketer, manager],
      path: parentPath + '/gbm/verification',
      component: GBMVerificationModal,
    },
    setting: {
      permission: [owner, marketer, manager],
      path: parentPath + '/gbm/setting',
      component: GBMSettingModal,
    },
  },
  gbmLocation: {
    permission: [owner, marketer, manager],
    path: parentPath + '/gbm-location',
    component: Loadable_GBMLocations,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/gbm-location' + createPath,
      component: GBMLocationModal,
    },
  },
  appleBusinesses: {
    permission: [owner, manager, marketer],
    path: parentPath + '/apple-business',
    component: Loadable_Apple_Business,
    create: {
      permission: [owner, marketer, manager],
      path: parentPath + '/apple-business' + createPath,
      component: AppleBusinessModal,
    },
    update: {
      permission: [owner, marketer, manager],
      path: parentPath + '/apple-business' + updatePath,
      component: AppleBusinessModal,
    },
  },
  oauth2: {
    permission: [owner, manager],
    path: '/oauth2',
    component: Loadable_OAuth2,
  },
};
